'use strict';
if (window && !window.$) { // this check is for SG, in SG window.$ is already exist and throw error on reinitialize
    window.jQuery = window.$ = require('jquery');
}

var $prCategorySnippets = $('.pr-category-snippet');
var $prWarForm = $('.pr-war-form');
var $prPdpComponents = $('.pr-pdp-components');

/* eslint-disable no-undef */

/**
 * @function prLoadCategorySnippets
 */
function prLoadCategorySnippets() {
    var prCategorySnippets = [];
    if ($prCategorySnippets.length > 0) {
        $($prCategorySnippets).each(function (index) {
            if ($(this).data('pr-plp-component')) {
                prCategorySnippets.push($(this).data('pr-plp-component'));
            }
        });
    }
    if (prCategorySnippets.length > 0) {
        pwr('render', prCategorySnippets);
    }
}

/**
 * @function prLoadWarForm
 */
function prLoadWarForm() {
    if ($prWarForm.length > 0 && $prWarForm.data('pr-war-form')) {
        var prWarFormData = $prWarForm.data('pr-war-form');
        // add on_submit callback method
        prWarFormData.on_submit = function () { // eslint-disable-line
            window.scrollTo(0, 0);
        };
        pwr('render', prWarFormData);
    }
}

/**
 * @function prLoadPdpComponents
 * @param {Object} powerReviewsData --powerReviewsData from product variant
 */
function prLoadPdpComponents(powerReviewsData) {
    if ($prPdpComponents.length > 0 || powerReviewsData) {
        var prPdpData = powerReviewsData || $prPdpComponents.data('pr-pdp-components');
        pwr('render', prPdpData);
    }
}

window.pwr = window.pwr || function () {
    (pwr.q = pwr.q || []).push(arguments);
};

$(document).ready(function () {
    if (window.POWERREVIEW) {
        if ($prWarForm.length > 0) { // only fire if user is on write review page
            prLoadWarForm();
        } else {
            $(document).ajaxComplete(function (event, xhr, settings) {
                if (settings.url.includes('Search-UpdateGrid') || $('#search-result-items').length > 0 || $('#product-search-results').length > 0 || $('#content-search-results-pane').length > 0) {
                    /* need to get new number of product
                    divs if added new (#search-result-items is for SG) */
                    $prCategorySnippets = $('.pr-category-snippet');
                    prLoadCategorySnippets();
                }
                if ($('#product-content').length > 0) { // this is for SG PDP variant change
                    $prPdpComponents = $('.pr-pdp-components');
                    prLoadPdpComponents();
                }
            });
            if ($prCategorySnippets.length > 0) { // only fire if user is on PLP page
                prLoadCategorySnippets();
            }
            if ($prPdpComponents.length > 0) { // only fires if user is on PDP page
                prLoadPdpComponents();
            }
            $('body').on('product:updateAvailability', function (e, response) {
                prLoadPdpComponents(response.product.powerReviewsComponents);
            });
        }
    }
});
